const ip_vpn_check = async (ip) => {
  try {
    let response = await fetch(`https://vpnapi.io/api/${ip}?key=7c9501d75c9f49409b1bc156a20f484a`)

    if (!response.ok) {
      return false;
    }
  
    let ip_data = await response.json();
  
    if (ip_data) {
      return ip_data.security.vpn || ip_data.security.tor || ip_data.security.proxy || ip_data.security.relay || false;
    } 
  
    return false;
  } catch {
    return false;
  }
  
}

export default ip_vpn_check;
