const ip_lookup = async () => {
  try {
    let response = await fetch('https://api.ipdata.co/?api-key=04b5325895b47bb45160badb92c28d0df8d7e7322015ee147ab10115')

    if (!response.ok) {
      // Return blank data if fetch issue
      return { 'ip': '', 'country': '' };
    }
  
    let ip_data = await response.json();
  
    return { 'ip': ip_data.ip, 'country': ip_data.country_name };
  } catch {
    // Return blank data if fetch issue
    return { 'ip': '', 'country': '' };
  }
  
}

export default ip_lookup;
