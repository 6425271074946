import React from 'react';
import * as FlexWebChat from "@twilio/flex-webchat-ui";
import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import CloseButton from "./components/CloseButton";


export default function App() {
  return (
    <Router basename="/">
      {/*
        A <Switch> looks through all its children <Route>
        elements and renders the first one whose path
        matches the current URL. Use a <Switch> any time
        you have multiple routes, but you want only one
        of them to render at a time
      */}
      <Switch>
        <Route exact path="/">
          <Chat configuration={window.appConfig}/>
        </Route>
      </Switch>
    </Router>
  );
}

// You can think of these components as "pages"
// in your app.

class Chat extends React.Component {

  state = {};

  constructor(props) {
    super(props);

    const { configuration } = props;

    FlexWebChat.Manager.create(configuration)
      .then(manager => {
        const endChatUrl = "https://end-webchat-6267.twil.io/endChat";
        FlexWebChat.MessageList.Content.add(<CloseButton key="close-chat" endChatUrl={endChatUrl} manager={manager}/>);
        
        this.setState({ manager })
      })
      .catch(error => this.setState({ error }));

    FlexWebChat.MessagingCanvas.defaultProps.predefinedMessage = {
      body: `
      Thank you for contacting Scotland’s Domestic Abuse and Forced Marriage Helpline.

      If you are in immediate danger, please phone 999 for the Police.

      Our helpline is here 24/7 to support anyone with experience of domestic abuse or forced marriage, as well those who support them.

      We provide a confidential, sensitive service to anyone who contacts us. We will only share information with another agency without your consent if we are concerned for the safety of a child, a vulnerable adult, or where we believe there is a life-threatening situation.

      When you are ready to start the chat please send us a message. You may want to start by telling us a little about what brought you to get in touch, or you can just say hello.`,
      authorName: "SDAFMH"
    }

    // Add privacy policy link
    FlexWebChat.PreEngagementCanvas.Content.add(
      <div className="privacy-policy" key="privacypolicy">
        <a target="_blank" rel="noopener noreferrer" href="https://sdafmh.org.uk/wp-content/uploads/2019/01/Privacy-Notice-SDAFMH.pdf">Privacy Policy</a>
      </div>
    );

    // Add exit button
    function exitSite() {
      window.open('https://www.bbc.co.uk/weather/', '_newtab');
      window.location.replace('http://google.com');
    }

    FlexWebChat.PreEngagementCanvas.Content.add(
      <div className="exit-site" key="exitsite">
        <button onClick={exitSite}>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
            <title>cancel</title>
            <path d="M20.145 18.422l-4.422-4.422 4.422-4.422-1.723-1.723-4.422 4.422-4.422-4.422-1.723 1.723 4.422 4.422-4.422 4.422 1.723 1.723 4.422-4.422 4.422 4.422zM14 1.768q5.054 0 8.643 3.589t3.589 8.643-3.589 8.643-8.643 3.589-8.643-3.589-3.589-8.643 3.589-8.643 8.643-3.589z"></path>
          </svg>
          <span>Exit site</span>
        </button>
      </div>
    );

    FlexWebChat.MessagingCanvas.Content.add(
      <div className="exit-site" key="exitsite">
        <button onClick={exitSite}>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
            <title>cancel</title>
            <path d="M20.145 18.422l-4.422-4.422 4.422-4.422-1.723-1.723-4.422 4.422-4.422-4.422-1.723 1.723 4.422 4.422-4.422 4.422 1.723 1.723 4.422-4.422 4.422 4.422zM14 1.768q5.054 0 8.643 3.589t3.589 8.643-3.589 8.643-8.643 3.589-8.643-3.589-3.589-8.643 3.589-8.643 8.643-3.589z"></path>
          </svg>
          <span>Exit site</span>
        </button>
      </div>
    )

    // Open the chat window by default
    FlexWebChat.Actions.invokeAction("ToggleChatVisibility");

    // Restart the chat when the page is refreshed
    FlexWebChat.Actions.invokeAction("RestartEngagement");
  }

  render() {
    const { manager, error } = this.state;
    if (manager) {
      return (
        <FlexWebChat.ContextProvider manager={manager}>
          <FlexWebChat.RootContainer />
        </FlexWebChat.ContextProvider>
      );
    }

    if (error) {
      console.error("Failed to initialize Flex Web Chat", error);
    }

    return null;
  }
}