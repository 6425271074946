import React from 'react';

export default class VpnMessage extends React.Component {
  /**
   * Builtin React method called to render this component
   */
  render() {
    return <div className="vpn-message">
        <h1>VPN detected</h1>
        <p>This is an automated message. Your web chat connection has been blocked.</p>

        <p>Unfortunately, we cannot provide our web chat service to you right now. Due to a high volume of hoax chats from Virtual Private Network (VPN) users, we no longer accept web chats using a VPN. Our system has detected a risk that you may be using a VPN, so we cannot accept this chat.</p>

        <p>We understand there are genuine reasons for using VPNs too and we sincerely apologise if this prevents you from using web chat. If you can safely disconnect your VPN, please start a new chat. If not, you can still call our helpline on <b>0800 027 1234</b>, email <a href="mailto:helpline@sdafmh.org.uk">helpline@sdafmh.org.uk</a>, or text or WhatsApp us at <b>07401 288595</b>.</p>

        <p><b>If you're concerned for your safety or someone else's, please call the Police on 999 in an emergency, or on 101 for non-emergencies.</b></p>
        
        <p>For additional support, you can find a list of other services on our website: <a href="https://www.sdafmh.org.uk/en/information-support/who-else-can-help/">https://www.sdafmh.org.uk/en/information-support/who-else-can-help/</a>.</p>
    </div>
  }
}